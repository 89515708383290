<div class="generalF">
    <div class="header-feria">
            <div class="headOne">
                <img class="pieza-midc" src="assets/img/landings/feria/D_titulo.svg" alt="Feria Midatacrédito">
                <div class="clockMobile">
                    <div class="clockItems">
                        <div class="segment">
                            <p><span>{{day <= 0? '0':day}} :</span> <br>Días</p>
                        </div>
                        <div class="segment">
                            <p><span>{{hours <= 0? '0':hours}} :</span> <br>Horas</p>
                        </div>
                        <div class="segment">
                            <p><span>{{minutes <= 0? '0':minutes}} :</span> <br>Minutos</p>
                        </div>
                        <div class="segment">
                            <p><span>{{seconds <= 0? '0':seconds}}</span> <br>Segundos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contOne">
                <p class="principalTXT"><b>¿Qué vas a poder hacer?</b><br><br>
                    En la feria <b>Midatacrédito - Bogotá 2025</b>, seguimos en la misión de construir país a través de la educación financiera de miles de colombianos a través de:</p>
                <div class="bubbles">
                    <div class="bubble"><img  src="assets/img/landings/feria/LFB25_Deudas.png" alt="Negociación de deudas"><p><b>Poner al día</b> deudas.</p></div>
                    <div class="bubble of"><img  src="assets/img/landings/feria/LFB25_Ofertas.png" alt="Negociación de deudas"><p><b>Ofertas Financieras</b> según tu perfil.</p></div>
                    <div class="bubble ef"><img  src="assets/img/landings/feria/LFB25_Educacion.png" alt="Negociación de deudas"><p>Actividades de <b>Educación financiera.</b></p></div>
                    <div class="bubble gr"><img  src="assets/img/landings/feria/LFB25_Historia_Credito.png" alt="Negociación de deudas"><p>Conocer <b>gratis</b> tu <b>Historia de Crédito.</b></p></div>
                    <div class="bubble pg"><img  src="assets/img/landings/feria/LFB25_Puntaje.png" alt="Negociación de deudas"><p>Conocer <b>gratis</b> tu <b>Puntaje de Crédito.</b></p></div>
                </div>
                <p class="principalTXT">¡Te esperamos para que empieces a tomar el <b>control de tus finanzas!</b></p>
            </div>
    </div>
    <div class="generalInfo">
        <h3>Únete a la 3a edición de nuestra feria:</h3>   
            <div class="data">
                <img src="assets/img/landings/feria/CUANDO.png" alt="Sábado 27 Abril">
                <h4>Sábado 15 de Marzo 2025</h4>
                <p>¿Cuándo?</p>
            </div>
            <div class="data">
                <img src="assets/img/landings/feria/DONDE.png" class="map" alt="Av. 68 # 49A-47">
                <h4>Corferias, Bogotá.</h4>
                <p>¿Dónde?</p>
            </div>
            <div class="data">
                <img src="assets/img/landings/feria/A-QUE-HORA.png" alt="8:00 a.m a 5:00 p.m">
                <h4>9:00 a.m a 5:00 p.m</h4>
                <p>¿A qué hora?</p>
            </div>
            <div class="dataMobile d-flex d-md-none">
                <img src="assets/img/landings/feria/CUANDO.png" alt="Sábado 27 Abril">
                <div class="txtMobile">
                    <h4>Sábado 15 de Marzo 2025</h4>
                    <p>¿Cuándo?</p>
                </div>
            </div>
            <hr class="sep">
            <div class="dataMobile d-flex d-md-none">
                <img src="assets/img/landings/feria/DONDE.png" class="map" alt="Av. 68 # 49A-47">
                <div class="txtMobile">
                    <h4>Corferias, Bogotá.</h4>
                    <p>¿Dónde?</p>
                </div>
            </div>
            <hr class="sep">
            <div class="dataMobile d-flex d-md-none">
                <img src="assets/img/landings/feria/A-QUE-HORA.png" alt="8:00 a.m a 5:00 p.m">
                <div class="txtMobile">
                    <h4>9:00 a.m a 5:00 p.m</h4>
                    <p>¿A qué hora?</p>
                </div>
            </div>
        </div>
    <div class="banner-div">
        <div id='div-gpt-ad-1712871841570-0' class="commercial-banner" [ngClass]="checkBanner()"></div>
    </div>
    <div class="seccionTwo">
        <div class="sponsor" *ngIf="flagSponsors">
            <!-- <div class="desc">
                <p>Hasta <br> <span>XX%</span> <br> descuento</p>
                <p class="add">+</p>
                <p>Negociación <br> <span>100%</span> <br> gratis</p>
            </div> -->
            <h2 class="txtAliados">Marcas participantes:</h2>
            <div class="aliados">
                <img *ngFor="let sponsor of sponsors" src="{{sponsor.icon}}" alt="{{sponsor.name}}">
            </div>
        </div>
        
        <div class="out">
            <h2>¡Desde cualquier lugar!</h2>
            <p>Ingresa a <a href="https://www.midatacredito.com/" target="_blank" (click)="send_datalayer('www.midatacredito.com')" >www.midatacredito.com</a>, inicia sesión o regístrate, revisa tu historia de crédito, haz clic en el botón <b>PONERME AL DÍA</b>, contáctate con las entidades acreedoras y empieza a negociar.</p>
            <button (click)="goLogin()">Ir a Midatacrédito</button>
        </div>
    </div>
</div>