import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { IdentityAddData } from '../models/identity.addData.model';
import { ContactRequest } from '../models/contactRequest.model';
import { environment } from '../../../environments/environment';
import { Notificacion } from '../models/notificationTypes.model';
import { Alerta } from '../models/alerta.model';
import {simulator } from '../models/resumenhc.model';
import { CookieService } from 'ngx-cookie-service';
import { ObjectTextDiagnostic } from 'src/app/core/models/diagnostico.model';

const headers: any = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class BrokerService {
  boton: any;
  promov = 0;
  llave: any;
  alerta: any;
  errorOneShot = 0;
  footerFree = 1;
  expirationDate: any;
  nextPaymentdDate: any;
  subscriptionDays: any;
  discount: any;
  payUtcConsult: any;
  customerKey: any;
  remarkety_id: string = '';
  store_id: string = '';

  constructor(public http: HttpClient, private cookieService: CookieService) {

  }

  // INFORMACION PLANES
  obtener_planes() {
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.infoPlanes, { headers });
  }
  // INFORMACION PLANES PROMO
  obtener_planes_promo() {
    return this.http.get('assets/content/planes-promo.json');
  }

  // TEST
  getBanners() {
    return this.http.get('assets/content/test.json');
  }
  // PLANES UPSELLING
  obtener_upselling(cookie: string) {
    const body = {
      sesionId: cookie
    };
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.upsellingInfo, body, { headers });
    // return this.http.get('assets/content/upselling-msn.json');
  }
  // INFORMACION FINACIERA
  obtener_tips_financieros() {
    return this.http.get('assets/content/educion-financiera.json');
  }
  confirmar_plan(cookie: string, plan: any) {
    const body = {
      sesionId: cookie,
      recommendedPlan: plan
    };
    return this.http.post<any>(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.upsellingPlan, body, { headers });
    // return this.http.get('assets/content/upselling-plan.json');
  }

  // HISTORIA DE CREDITO
  infoHC(cookie: string) {
    const body = JSON.stringify({
      sessionId: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.getFullCreditHistory, body, { headers });
  }

  // FREEPREMIUM
  freepremium() {
    const body = JSON.stringify({
      sessionId: this.cookieService.get('IDSESSIONMDC')
    });
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.freepremium, body, { headers });
    //return this.http.get('assets/content/creditHistoryFreemium.json');
  }

  // RECLAMACIONES
  TiposReclamaciones() {
    // tslint:disable-next-line: max-line-length
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.consultarTiposReclamos, { headers })
    
  }

  CrearReclamacion(reclamacionRequest: any) {

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.create, reclamacionRequest, { headers });
  }

  consultarReclamo(cookie: string, cuenta: string) {
    const body = JSON.stringify(
      {
        userSession: {
          sessionId: cookie
        },
        accountE: cuenta
      }
    );

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.reclamo, body, { headers });
  }

  // verificarLlave( cookie: string, llave: string ) {
  //   const body = JSON.stringify(
  //     {
  //       sessionId: cookie,
  //       key: llave
  //     }
  //   );
  //   // tslint:disable-next-line: max-line-length
  //   return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.verificarLlave , body,  { headers });
  // }

  // novedatSession( cookie: string ) {
  //   const body = JSON.stringify(
  //     {
  //       sessionId: cookie
  //     }
  //   );
  //   // tslint:disable-next-line: max-line-length
  //   return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.novedatsession , body,  { headers });
  // }

  // novedatVisit( cookie: string, stateAccount: string ) {
  //   const body = JSON.stringify(
  //     {
  //       sessionId: cookie,
  //       stateAccount
  //     }
  //   );
  //   // tslint:disable-next-line: max-line-length
  //   return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.novedatvisit , body,  { headers });
  // }

  // SESION REDDIS
  validationSession(cookie: string) {
    const body = JSON.stringify({
      clientId: environment.oktaConfig.clientId,
      country: environment.country,
      idSession: cookie,
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.validationSession, body, { headers });
  }
  cerrarSesion(cookie: string) {
    const body = {
      clientId: environment.oktaConfig.clientId,
      country: environment.country,
      idSession: cookie,
    };
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.signOff, body, { headers });
  }

  // INFORMACION TEXTOS DARKWEB
  obtener_textosdkw() {
    return this.http.get('assets/content/textosDarkWeb.json');
  }

  // ESCANEO
  generic(email: string) {
    const body = JSON.stringify({
      serviceCode: '307',
      extTransactionData: {
        identity: {
          idEmailAddr: [
            email
          ]
        }
      }
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.generic, body, { headers });
  }

  // CREATE USER DARK WEB
  createDKW(cookie: string) {
    const body = JSON.stringify({
      serviceCode: '500',
      session: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.identity, body, { headers });
  }
  // GET USER DARK WEB
  getDKW(cookie: string) {
    const body = JSON.stringify({
      session: cookie
    });


    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.info, body, { headers });
  }

  // CONTADOR NOTIFICACIONES DARK WEB
  countDKW(cookie: string) {

    const body = JSON.stringify({
      session: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.countdw, body, { headers });
  }

  // LISTADO NOTIFICACIONES DARK WEB
  listDKW(cookie: string, pagina: number, items: number) {
    const body = JSON.stringify({
      session: cookie
    });


    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.listdw + '/' + pagina + '/' + items, body, { headers });
  }

  // LISTADO NOTIFICACIONES DARK WEB
  readDKW(idAlertRead: string) {
    const body = JSON.stringify({
      idAlert: idAlertRead
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.readdw, body, { headers });
  }

  // AGREGAR INFORMACION ADICIONAL DARKWEB
  addDKW(body: IdentityAddData) {

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.identity, body, { headers });
  }

  // AGREGAR INFORMACION ADICIONAL DARKWEB
  editDKW(body: IdentityAddData) {

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.identity, body, { headers });
  }

  // PROBABILIDAD PROXIMO CREDITO
  probabilidadCredito(cookie: string) {
    const body = JSON.stringify({
      simulador: 'GENERAL',
      session: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.probabilidad, body, { headers });
  }

  // SIMULAR PROXIMO CREDITO
  simularCredito(requestSimulador: any) {
    const body = requestSimulador;
    body.tasa = body.tasaI / 100;

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.simular, body, { headers });
  }

  // NOTIFICACIONES

  notificaciones(cookie: string, readMark: string) {
    const body = JSON.stringify({
      id_session: cookie,
      readMark
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.notificaciones, body, { headers });
  }

  // CONSULTAR NOTIFICACIONES
  consultarNotificaciones(cookie: string) {
    const body = JSON.stringify({
      id_session: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.consultaNotificaciones, body, { headers });
  }
  bell(cookie: string) {
    const body = JSON.stringify({
      id_session: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.bell, body, { headers });
  }

  // HUELLAS
  huellas(cookie: string) {
    const body = JSON.stringify({
      sessionId: cookie
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.huellas, body, { headers });
  }

  // PQRS
  obtener_pqrs() {
    return this.http.get('assets/content/pqrs.json');
  }

  // Enviar contacto
  enviarContacto(data: ContactRequest) {
    const body = JSON.stringify(data);

    // tslint:disable-next-line: no-shadowed-variable
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.contact, body, { headers });
  }

  // Activar / Desactivar celular
  codigo(celular: string, cookie: any) {
    const body = JSON.stringify({
      sessionId: cookie,
      deviceNumber: celular
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.codigo, body, { headers });
  }

  validar(celular: any = null, codigo: string, cookie: any) {
    const body = JSON.stringify({
      sessionId: cookie,
      deviceNumber: celular,
      code: codigo
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.validar, body, { headers });
  }

  desactivar(cookie: any) {
    const body = JSON.stringify({
      sessionId: cookie,
      state: false
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.active, body, { headers });
  }

  // Consultar y guardar alertas permitidas

  getAlertsMongoWS(cookie: string) {
    const body = JSON.stringify({
      id_session: cookie
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.getAlertsMongoWS, body, { headers });
  }

  saveAlertsMongoWS(cookie: any, notificationes: Array<Notificacion>) {
    const body = JSON.stringify({
      id_session: cookie,
      notifications: notificationes
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.saveAlertsMongoWS, body, { headers });
  }

  // Listado países y ciudades
  obtener_paises() {
    return this.http.get('assets/content/paises.json');
  }
  obtener_ciudades() {
    return this.http.get('assets/content/ciudades.json');
  }

  // Obtener listado de bancos
  listadoBancos() {

    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.bancos, { headers });
  }

  checkPendingPayments(cedula: string) {
    const body = JSON.stringify({
      cedula
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.check, body, { headers });
  }

  // Pagos
  pagos(body: any) {

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.pagos, body, { headers });
  }
  // gifcards/promos
  gifCard(body: any) {

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.gifCard, body, { headers });
  }

  listarAlertas() {
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.listar, { headers });
  }

  crearAlerta(cookie: string, codigo: string, description: string) {
    const body = JSON.stringify({
      sesion: cookie,
      alertCode: codigo,
      alertText: description
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.crear, body, { headers });
  }

  consultarAlerta(cookie: string) {
    const body = JSON.stringify({
      sesion: cookie
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.consultar, body, { headers });
  }

  // Facturacion
  consultarFacturas(cookie: string) {
    const body = JSON.stringify({
      sessionId: cookie
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.document, body, { headers });
  }

  descargarFactura(cookie: string, invoiceNumber: string) {
    const body = JSON.stringify({
      sessionId: cookie,
      invoiceNumber
    });

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.download, body, { headers });
  }

  // Informacion adicional
  datosUsuario(cookie: string) {
    const body = JSON.stringify(
      {
        product: "MIDATACREDITO",
        sessionId: cookie
      }
    );

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.sessioninfo, body, { headers });
  }

  // Validar evidente
  validarEvidente(cookie: string) {
    const body: string = cookie;

    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.validateEvidente, body, { headers });
  }

  // Registro V2
  regfirstSaveAuthn(body: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.registerUser, body, { headers });
  }
  updatePasswordSession(body: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.updatePSession, body, { headers });
  }
  firstAuthn(body: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.firstAuthn, body, { headers });
  }
  saveSession(body: any, token: any) {
    // tslint:disable-next-line: no-shadowed-variable
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.saveSession, body, { headers });
  }

  /********* PayU *********/
  irPayU(body: any) {
    return this.http.post(environment.APIEndPoints.urlEndVeri + environment.APIEndPoints.irPayU, body, { headers });
  }

  /********* Reportar Mora *********/
  reportarMora(body: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.reportarMora, body, { headers });
  }

  /********* Consultar Mora *********/
  consultarMora(body: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.consultarMora, body, { headers });
  }

  /********* Ponte al día *********/
  consultarDeudas() {
    const body = JSON.stringify({
      sessionID: this.cookieService.get('IDSESSIONMDC')
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.consultaDeudas, body, { headers });
  }

  getUrlEscala(idConsumerdebts: String) {
    const body = JSON.stringify({
      sessionID: this.cookieService.get('IDSESSIONMDC'),
      idConsumerdebts: idConsumerdebts
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.urlEscala, body, { headers });
  }

  registerPadYaImpressions(impressions : Array<object>) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.captarImpresionesPadYa, impressions, { headers });
  }

  // Nuevos Términos y Condiciones
  guardarTerminos(body: any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.addCustomerLog, body, { headers });
  }

  // Aceptar publicidad
  aceptarPublicidad(body: any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.saveAcceptanceAdvertising, body, { headers });
  }

  // Trae información necesaria para realizar pago por payU.
  paymentWC(document: any): any {
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.paymentWC + '/' + document, { headers });
  }

  confirmationWC(formData: any): any {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.confirmationWC, formData, { headers });
  }

  // Request para remarkety
  request_remarkety(data: any, method: string){
    const storeId = this.store_id;
    const token = this.remarkety_id;
    const eventType = method;// See "Event types"
    
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Event-Type': eventType,
      'X-Token': token,
      'Cache-Control': 'no-cache'
    });

    return this.http.post(environment.webhooks + storeId, data, { headers });
  }

  consultarMetodoPago() {
    const body = JSON.stringify({
      session: this.cookieService.get('IDSESSIONMDC')
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.consultMethod, body, { headers });
  }

  cambiarMetodoPago(body: any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.changeMethod, body, { headers });
  }

  consultTcPayU(id: string) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
      'Authorization': id
    });
    return this.http.get('https://api.payulatam.com/payments-api/rest/v4.9/creditCards/' + this.payUtcConsult, { headers });
  }

  activateEmailFree(deviceNumber: any, activator: any) {
    let data = {
      sessionId: this.cookieService.get('IDSESSIONMDC'),
      deviceNumber: deviceNumber
    }
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.updateFree + '?activador=' + activator, data, { headers });
  }

  downloadPdf() {
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.sendPdfToEmail + "/" + this.cookieService.get('IDSESSIONMDC'), { headers });
  }

  getHistoryScore() {
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.historyScore + "/" + this.cookieService.get('IDSESSIONMDC'), { headers });
  }

  // INFORMACION PLANES PROMO
  getParametersRisk() {
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.parametrosSemaforo);
  }

  getTrafficScore(data: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.trafficscore, data, { headers });
  }

  /** Servicio para obtener la parametria del modal para las intermitencias */
  getIntermittenceParameter() {
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.parametrosIntermitencia, { headers });

  }

  getMenu() {
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.parametrosMenu, { headers });
    //return this.http.get("assets/content/menu.json")
  }
  getAdvanceScore() {
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.parametrosScoreAdvance, { headers });
  }
  getInfoModalHome(){
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.parametrosModalHome, { headers });
  }

  /** boton PAD cuentas abiertas */
  registerApplyPAD(item: any, debtTime: string, debtValue: string, accountNumber: string,sourceOfTraffic:string,campaignOfTraffic:string,mediumOfTraffic:string,consumerNumber?: string ) {
    let body:any = {
      sessionId: this.cookieService.get('IDSESSIONMDC'),
      itemEntity: item,
      debtTime: debtTime,
      debtValue: debtValue,
      accountNumber: accountNumber,
      sourceOfTraffic: sourceOfTraffic,
      campaignOfTraffic: campaignOfTraffic,
      mediumOfTraffic: mediumOfTraffic

    };
    if(consumerNumber != undefined){
      body["consumerNumber"] = consumerNumber;
    }
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.registerApplyButtonPAD, body, { headers });
  }
  /** simulador */

  //textos del simulador
  getScenarySimulator(scenary:simulator) {
    let cookie = this.cookieService.get('IDSESSIONMDC');
    let route = "midatacredito/simulator";
    let high = scenary.high.trim();
    
    let medium = scenary.medium.trim();
    let low = scenary.low.trim();
    const params = `/${cookie}/${route}/${low}/${medium}/${high}`;
    
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.scenarios+params);
  }

  //simulador de puntajes
  getScoreSimulator(scenary:number) {
    let cookie = this.cookieService.get('IDSESSIONMDC');
    const params = `/${cookie}/${scenary}`;
    
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.score+params);
  }

  getPolicy() {
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.terminosCondicionesApp, { responseType: 'text' });
  }

  loadParameters(key: string) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'key': key,
      'app': 'MIDC'
    });
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.loadParameters, { headers });
  }

  getSponsorsFeria(){
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.sponsorsFeria, { headers });  
  }

  getFaq() {
    return this.http.get("assets/content/faq.json")
  }

  getAlliesPad(){
    return this.http.get(environment.enlaceS3 + environment.s3.pntdPath);
    // return this.http.get("assets/content/aliados.json");
  }

  setUtmPago(data: any) {
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.setUtmPago, data, { headers });
  }

  getAthorizations(cookie:string){
    return this.http.get(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.authorizations + '/' + cookie, { headers });
  
  }
  setAutorizations(data:any){
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.updateAuthorizations, data, { headers });
  }

  getTextDiagnostic(text:ObjectTextDiagnostic){
    let data = {texts:text};
    return this.http.post(environment.APIEndPoints.urlEndPoints + environment.APIEndPoints.robustDiagnostic, data, { headers });
  }
  getAllLogos(){
    const headers: any = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.get(environment.enlaceS3 + environment.s3.allLogos, { headers });  
  }

}