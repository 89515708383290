import { Component, OnInit, Input } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SesionService } from 'src/app/core/services/sesion.service';

export interface debt {
  entityName?: string,
  typDebt?: string,
  stateDebt?: string,
  accountDebt?: string,
  debtTime?: string,
  debtValue?: string,
  sourceDebt?: string,
  debtDetail?: any
}
@Component({
  selector: 'app-dash-pad-module',
  templateUrl: './dash-pad-module.component.html',
  styleUrls: ['./dash-pad-module.component.scss']
})
export class DashPadModuleComponent implements OnInit {

  public debts: Array<debt> = [];
  public debtsBy3: Array<Array<debt>> = [];
  // public isLoadedDebts = false;
  public erroLoadDebts = false;
  @Input() infoPadYa: any = {};
  @Input() isApp: boolean = false;


  constructor(public broker: BrokerService, public sesion: SesionService) { }

  ngOnInit(): void {
    this.getDebtsPad();

  }

  /**
   * Funcion para dejar un array de arrays de maximo 3 elementos para las deudas para que se visualicen segun diseño en desktop
   */
  setDebtsMax3() {

    let cont = 0;
    let numDebts = this.debts.length;
    let array3: any = []
    this.debts.forEach((ele: object) => {
      array3.push(ele);
      cont++;
      numDebts--;
      if (cont == 3) {
        this.debtsBy3.push(array3);
        array3 = [];
        cont = 0;
      }
      if (numDebts == 0) {
        if (array3.length > 0) {
          this.debtsBy3.push(array3);
          array3 = [];
        }
      }
    });
  }

  /**
   * Funcion que invoca el servicio pontealdia/v1/debt, que trae las deudas configuradas en pad tradicional, guarda la informacion necesaria en un objeto general de deuda 
   * y luego invica un metodo para obtener la deudas de padya
   */
  getDebtsPad() {
    this.debts = [];
    this.sesion.isLoadedDebts = true;
    if (this.sesion.configurationPad == 0 || this.sesion.configurationPad == 1) {
      this.broker.consultarDeudas().subscribe({
        next: (result: any) => {
          if (result.responseObject != null) {
            result.responseObject.debts.forEach((element: any) => {
              let newDebt = {} as debt;
              newDebt.accountDebt = '***** ' + element.numberAccount;
              if (element.delinquencyInDays == 1) {
                newDebt.debtTime = element.delinquencyInDays + ' día';
              } else {
                newDebt.debtTime = element.delinquencyInDays + ' días';
              }
              newDebt.entityName = element.nameCompany;
              newDebt.stateDebt = element.accountStatuts;
              newDebt.typDebt = element.nameProduct;
              newDebt.debtValue = element.totalDebt;
              newDebt.sourceDebt = 'pad';
              newDebt.debtDetail = element;
              this.debts.push(newDebt);
            });
          }
          this.getDebtsPadYa();
        },
        error: (error: any) => {
          this.getDebtsPadYa();
        }
      });
    } else {
      this.getDebtsPadYa();
    }
  }

  /**
   * Funcion que busca en la HC las cuentas con deudas y que tengan padya, y guarda la informacion necesaria en un objeto general de deuda 
   * y al terminar llama a la funcion para que acomodar las deudas para visual de desktop y mobile
   */
  getDebtsPadYa() {
    try {
      if (this.sesion.configurationPad == 0 || this.sesion.configurationPad == 2) {
        this.infoPadYa.openAccounts.forEach((element: any) => {
          let accountPadYa = element.detalleCuenta.filter((ele2: any) => ele2.enlacePad != null && ele2.enlacePad != '');
          accountPadYa.map((ele: any) => ele['type'] = element.nombreCuenta);
          accountPadYa.forEach((element2: any) => {
            let newDebt = {} as debt;
            newDebt.accountDebt = '***** ' + element2.numeroCuenta.split('*')[element2.numeroCuenta.split('*').length - 1];
            newDebt.debtTime = element2.estadoTexto;
            newDebt.entityName = element2.entidad;
            newDebt.stateDebt = (element2.tipoReporte == 'Negativo' ? 'EN MORA' : 'AL DIA');
            newDebt.typDebt = element2.type;
            newDebt.debtValue = element2.saldoMora;
            newDebt.sourceDebt = 'padya';
            newDebt.debtDetail = element2;
            this.debts.push(newDebt);
          });
        });
      }
      if (this.debts.length > 0) {
        this.sesion.showModulePad = true;
      }
      this.setDebtsMax3();
      this.sesion.isLoadedDebts = false;
    } catch (e) {
      if (this.debts.length > 0) {
        this.sesion.showModulePad = true;
      }
      this.sesion.isLoadedDebts = false;
      this.erroLoadDebts = true;
    }

  }

  addClass(event: Event, nameClass: string) {
    let htmlEle = event.target as HTMLElement;
    let children = htmlEle.parentElement?.parentElement?.children;
    if (children != undefined) {
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove(nameClass);
      }
    }
    htmlEle.parentElement?.classList.add(nameClass);
  }


}
