<div class="pad-content">
    <div class="container">
        <div class="row section-pad main">
            <div class="col-12 col-md-6">
                <h1>¿Tienes una deuda reportada en DataCrédito? Conoce cómo puedes negociar con nuestros aliados:</h1>
                <div class="steps">
                    <div class="step">
                        <img src="assets/img/landings/pad/icon_paso1_landing_padya.png" alt="Paso 1" title="Paso 1" />
                        <div>
                            <p>PASO 1:</p>
                            <P><b>Ingresa a Midatacrédito y regístrate o inicia sesión.</b></P>
                        </div>
                    </div>
                    <div class="step">
                        <img src="assets/img/landings/pad/icon_paso2_landing_padya.png" alt="Paso 2" title="Paso 2" />
                        <div>
                            <p>PASO 2:</p>
                            <P><b>Encuentra tu deuda.</b></P>
                        </div>
                    </div>
                    <div class="step">
                        <img src="assets/img/landings/pad/icon_paso3_landing_padya.png" alt="Paso 1" title="Paso 1" />
                        <div>
                            <p>PASO 3:</p>
                            <P><b>Entérate de cómo negociar y finaliza el pago con el aliado.</b></P>
                        </div>
                    </div>
                </div>
                <div class="btn-mobile">
                    <button (click)="goHome()">Ponerme al Día<i class="fa fa-angle-right"></i></button>
                </div>
                <!-- <div>
                    <button (click)="goHome()">Ponerme al Día<i class="fa fa-angle-right"></i></button>
                </div>             -->
            </div>
            <div class="col-6 text-center d-none d-md-block">
                <img class="img-pad" src="assets/img/landings/pad/img_landing_padYA_01.png" alt="Ponte al día"
                    title="Ponte al día" />
            </div>
        </div>
        <div class="row" *ngIf="this.sesion.viewFree">
            <div class="col-12">
                <div id='div-gpt-ad-1720186614588-0' class="commercial-banner">
                </div>
            </div>
        </div>
        <div class="row section-pad information">
            <div class="col-12 information-mini">
                <div class="mini-box-info" (click)="viewInfo(1)">
                    <img class="img-box" src="assets/img/landings/pad/img_como_pagar_landing_padya.png" alt="Como pagar"
                        title="Como pagar" />
                    <div>
                        <p>¿Cómo <b>pagar mi obligación</b> en mora?</p>
                        <p class="text-button">Cómo pagar <img
                                src="assets/img/landings/pad/icono_flecha_lanidng_padya.svg" alt="cómo pagar"
                                title="cómo pagar" /></p>
                    </div>
                </div>
                <div class="mini-box-info alter" (click)="viewInfo(2)">
                    <img class="img-box" src="assets/img/landings/pad/img_como_negociar_landing_padya.png" alt="Como negociar"
                        title="Como negociar" />
                    <div>
                        <p>¿Cómo <b>negociar mi obligación</b> en mora?</p>
                        <p class="text-button">Cómo negociar <img
                                src="assets/img/landings/pad/icono_flecha_lanidng_padya.svg" alt="cómo negociar"
                                title="cómo negociar" /></p>
                    </div>
                </div>
            </div>
            <div class="col-12 information-detail">
                <div id="box-detail-1" class="box-information-detail">
                    <div class="detail">
                        <img src="assets/img/landings/pad/bullet1_landing_padya.png" alt="Consulta tus obligaciones"
                            title="Consulta tus obligaciones" />
                        <div>
                            <p class="title">Consulta tus obligaciones</p>
                            <p>Inicia sesión o regístrate para consultar las obligaciones en mora que tienes disponibles
                                para negociar.</p>
                        </div>
                    </div>
                    <div class="detail">
                        <img src="assets/img/landings/pad/bullet2_landing_padya.png" alt="Enterate de cómo pagar"
                            title="Enterate de cómo pagar" />
                        <div>
                            <p class="title">Entérate de cómo pagar tu obligación</p>
                            <p>Haciendo clic en la obligación vas a poder conocer cómo ponerte al día pagando tu
                                obligación con el aliado.</p>
                        </div>
                    </div>
                    <div class="detail">
                        <img src="assets/img/landings/pad/bullet3_landing_padya.png" alt="Ponte al día"
                            title="Ponte al día" />
                        <div>
                            <p class="title">Ponte al día con el pago de tus deudas</p>
                            <p>Completa el pago con la entidad y resuelve tus deudas de manera rápida y sin filas.</p>
                        </div>
                    </div>
                </div>
                <div id="box-detail-2" class="box-information-detail alter d-none">
                    <div class="detail">
                        <img src="assets/img/landings/pad/bullet4_landing_padya.png" alt="Consulta tus obligaciones"
                            title="Consulta tus obligaciones" />
                        <div>
                            <p class="title">Consulta tus obligaciones</p>
                            <p>Inicia sesión o regístrate para consultar las obligaciones en mora que tienes disponibles
                                para negociar.</p>
                        </div>
                    </div>
                    <div class="detail">
                        <img src="assets/img/landings/pad/bullet5_landing_padya.png" alt="Negocia gratis"
                            title="Negocia gratis" />
                        <div>
                            <p class="title">Negocia GRATIS con nuestros aliados</p>
                            <p>Elige el plan de pago que mejor se ajuste a tu presupuesto para ponerte al día.</p>
                            <p class="d-block d-md-none">Consejo: Entre menos tiempo elijas, mayor es el descuento.</p>
                        </div>
                    </div>
                    <div class="detail">
                        <img src="assets/img/landings/pad/bullet6_landing_padya.png" alt="Ponte al día"
                            title="Ponte al día" />
                        <div>
                            <p class="title">Ponte al día con el pago de tus deudas</p>
                            <p>Finaliza tu acuerdo de pago con la entidad y resuelve tus deudas de manera rápida y sin
                                filas.</p>
                        </div>
                    </div>
                    <div class="d-none d-md-block" style="width: 100%;">
                        <p>Consejo: Entre menos tiempo elijas, mayor es el descuento.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row section-pad allies">
            <div class="col-12">
                <div class="text-center">
                    <h2>Aliados con los que podrás negociar:</h2>
                    <hr />
                </div>
                <div class="row logos">
                    <!-- <div *ngFor="let al of dataJsonAliados" class="col-6 col-md-2"> -->
                        <img  *ngFor="let al of dataJsonAliados" src="{{al.icon}}" alt="{{al.name}}" title="{{al.name}}" />
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div class="row" *ngIf="this.sesion.viewFree">
            <div class="col-12">
                <div id='div-gpt-ad-1720187114721-0' class="commercial-banner">
                </div>
            </div>
        </div>
        <div class="row section-pad testimonials">
            <div class="col-12">
                <div class="text-center">
                    <h2>Lo que dicen nuestros usuarios</h2>
                    <hr />
                </div>
                <div class="content-testimonials">
                    <div #testimonials *ngFor="let test of testimonials;let i = index;" class="box-testimonial">
                        <div class="icon-testimonial">
                            <img src="assets/img/landings/pad/testimonial_landing_padya.svg" title="Testimonial" alt="Testomonial"/>
                        </div>
                        <div class="qualify d-none d-md-block" id="qualify-testimonial-{{i}}">
                        </div>
                        <p>{{test.description}}</p>
                        <div class="d-flex">
                            <p class="author">{{test.author}}</p>
                            <div class="qualify d-block d-md-none" id="qualify-testimonial-m-{{i}}">
                            </div>
                        </div>
                    </div>
                    <!-- <div>
                        <p>******</p>
                        <p>Me hicieron un gran descuento, ideal para salir de la deuda.</p>
                        <p>Bernardo J.</p>
                    </div>
                    <div>
                        <p>******</p>
                        <p>Me hicieron un gran descuento, ideal para salir de la deuda.</p>
                        <p>Bernardo J.</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
