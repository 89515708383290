import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SesionService } from '../../core/services/sesion.service';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

	constructor(
		private activateRoute: ActivatedRoute,
		public sesion: SesionService
	) { 
	}

	ngOnInit() {
		
		let urlArticle = this.activateRoute.snapshot.paramMap.get('article');
		let uri = `https://blog.midatacredito.com/`;
		if(urlArticle != null){
			let encodeParams = encodeURIComponent(urlArticle);
			uri = `https://blog.midatacredito.com/${encodeParams}`;
		}
		let embebed = encodeURI(uri);

		let iframe = document.createElement("iframe");
		iframe.id = "frame";
		iframe.src=embebed;
		iframe.style.cssText = "width: 100%;height: 100vh;padding-top: 75px;"
		let elem = document.getElementById("boxBlog");
		if(typeof elem !== 'undefined' && elem !== null) {
			elem.appendChild(iframe);
		}
	}

}
