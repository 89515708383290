import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { Router } from '@angular/router';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
interface sponsor{
  id:number,
  name:string,
  icon:string,
}

@Component({
  selector: 'app-feria',
  templateUrl: './feria.component.html',
  styleUrls: ['./feria.component.scss']
})
export class FeriaComponent implements OnInit {
  sponsors:Array<sponsor>=[];
  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  clock: any;
  flagSponsors:boolean=false;
  public bannerCheck = false;
  public loginMidc = environment.enlaceLogin;

  constructor(public broker: BrokerService, private utilities: UtilitiesService, private sesion: SesionService, private dataLayer: DataLayersService) { }

  ngOnInit(): void {
    this.getSponsors();
    this.clock = this.source.subscribe(t => {
      this.now = new Date();
      this.end = new Date('3/15/' + this.now.getFullYear() +' 09:00');
      this.showDate();
    });
    // GA4 - user
    if (this.sesion.user){
      this.dataLayer.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dataLayer.dl_userId( null, 'anonimo' );
    }
  }
  
  showDate(){
    let distance = this.end - this.now;
    this.day = Math.floor(distance / this._day);
    this.hours = Math.floor((distance % this._day) / this._hour);
    this.minutes = Math.floor((distance % this._hour) / this._minute);
    this.seconds = Math.floor((distance % this._minute) / this._second);
  }

  getSponsors() {
    if(this.sesion.isEmpty(this.sesion.allLogos)){
      this.broker.getAllLogos().subscribe({
        next:(result:any)=>{
          this.sesion.allLogos = result;
          this.sponsors = this.sesion.allLogos.sponsorsFeria;
          this.flagSponsors = true;
        }
      });
    }else{
      this.sponsors = this.sesion.allLogos.sponsorsFeria;
      this.flagSponsors = true;
    }
  }

  // Banner publicitario de Google
  checkBanner() {
		if (!this.bannerCheck) {
      let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.feriamidc2024, 'div-gpt-ad-1712871841570-0', tamano);
			this.bannerCheck = true;
		}
		return ''
	}

  goLogin(){
    this.send_datalayer('Login midatacrédito');
    window.open(this.loginMidc, '_blank');
  }

  send_datalayer(option: string){
    this.dataLayer.dl_clic_button_name('clic_feria_landing', option);
  }
}
