import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SesionService } from 'src/app/core/services/sesion.service';
interface sponsor {
  id: number,
  name: string,
  icon: string,
}
@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  public sponsors: Array<sponsor> = [];
  constructor(public broker: BrokerService, public sesion: SesionService) { }

  ngOnInit(): void {
    this.getSponsors();
  }

  getSponsors() {
    if(this.sesion.isEmpty(this.sesion.allLogos)){
      this.broker.getAllLogos().subscribe({
        next:(result:any)=>{
          this.sesion.allLogos = result;
          this.sponsors = this.sesion.allLogos.homeMIDC;
        }
      });
    }else{
      this.sponsors = this.sesion.allLogos.homeMIDC;
    }
  }
  

}
