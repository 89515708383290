import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { NgForm } from '@angular/forms';
import { SuscripcionService } from '../../core/services/suscripcion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-escaneo',
  templateUrl: './escaneo.component.html',
  styleUrls: ['./escaneo.component.scss']
})
export class EscaneoComponent implements OnInit {

  email: any;
  termsDark = false;
  url_politica_privacidad = environment.enlaceS3 + environment.s3.politicaDePrivacidad;
  escaneo:any = null;
  error:boolean=false;
  loading:boolean=false;
  reports:boolean=false;

  constructor(
    public sesion: SesionService,
    public suscripcion: SuscripcionService,
    public broker: BrokerService,
    private dataLayerService: DataLayersService) {
    this.suscripcion.darkweb = false;
  }

  ngOnInit() {
  }

  escaneoAnonimo(datos: NgForm) {
    if (datos.valid) {
      this.loading=true;
      this.error=false;
      this.sesion.generic(this.email).subscribe(
        {
          next: (data: any) => {
            if(!data){
              
              this.error=true;
            }else{
              this.escaneo = data;
              if(this.escaneo.stlTransactionData.responseMetadata.recordCount !== 0){
                this.reports=true;
              }
            }
            this.loading=false;
          },
          error: (data: any) => {
            this.error=true;
            this.loading=false;
          }
        });
    }

    // GA4 - generate_lead
    this.dataLayerService.dl_generate_lead('0');
  }
  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any) {

    localStorage.setItem("lanProtege", "1");

    this.broker.promov = 1;
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;

    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'DRK');
    } else {
      this.suscripcion.planSesion(false, 'DRK');
    }
  }
  showTerms() {
    if ($($(".text-ad")[0]).css("display") == "none") {
      $($(".text-ad")[0]).slideDown()
    } else {
      $($(".text-ad")[0]).slideUp()
    }
  }
}
