<div class="escaneoNew" *ngIf="!loading && escaneo == null">
    <div class="boxHash left-hash">
        <div class="hash">
            <p>#MIDATAProtege</p>
            <i class="fa fa-sort-down" aria-hidden="true"></i>
        </div>
    </div>
    <h6>PROTEGER MI IDENTIDAD:</h6>
    <p class="txtPrin">Escanea <b>GRATIS</b>, conoce si tus datos personales se encuentran comprometidos en la web.</p>
    <form (ngSubmit)="escaneoAnonimo(datos)" #datos="ngForm" autocomplete="off">
        <div class="backForm">
            <div class="boxInput">
                <div class="formEsc iconEsc">
                    <i class=" fa fa-envelope-o"></i>
                </div>
                <div class="formEsc inputEsc">
                    <input type="text" class="form-control font-weight-lighter" name="mail" id="nameHolderP"
                        placeholder="Ingresa tu correo electrónico" required="required"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$" [(ngModel)]="email"
                        #emailAnonimo="ngModel"
                        [ngClass]="{'is-valid': emailAnonimo.valid, 'is-invalid': ( ( emailAnonimo.errors?.['required'] || emailAnonimo.errors?.['pattern'] ) && emailAnonimo.touched ) }">
                </div>
            </div>
                
            <div class="legalEsc">
                <input type="checkbox" [(ngModel)]="termsDark" name="termsContact" #termsConcat="ngModel" id="termsContact"/>
                <label for="termsContact">Autorizo el tratamiento de mis datos personales para que sean utilizados por Experian Midatacrédito de acuerdo con esta autorización de
                </label>
                <a (click)="showTerms()"><u>tratamiento de datos personales.</u><i class="fa fa-angle-down"></i></a>
                <p class="text-ad">
                    Al seleccionar este checkbox autorizo a EXPERIAN COLOMBIA S.A., identificada con NIT. 900.422.614-8 para
                    que, en su calidad de Responsable del Tratamiento, recopile y en general realice el tratamiento de mi correo
                    electrónico con el fin de (i) Almacenarlo en sus bases de datos; (ii) Adelantar las gestiones pertinentes
                    tendientes a identificar y escanear mis datos en la dark web y redes sociales, con el propósito de alertarme
                    sobre un posible uso indebido de ellos en la web; (iii) Enviarme comunicaciones asociadas al servicio de
                    análisis en la dark web; (iv) Atender requerimientos de autoridades en ejercicio de sus funciones; (v)
                    Conservarla de forma indefinida para fines relacionados con análisis estadísticos, desarrollo y mejora de
                    productos contratados, históricos y/o para cumplir con obligaciones legales respecto a conservación de
                    información; y (vi) Compartirla con terceros Encargados del Tratamiento para dar cumplimiento a las
                    finalidades previamente mencionadas. <br/>Declaro conocer que como titular de la información tengo derecho a
                    conocer, actualizar o rectificar mis datos personales, presentar quejas ante la Superintendencia de
                    Industria y Comercio, suprimir o eliminar mis datos de las bases de datos de EXPERIAN COLOMBIA, lo cual solo
                    procederá en caso de que no se tenga una obligación legal o contractual para mantenerla, así como solicitar
                    prueba de la autorización otorgada o revocarla, contactándome por el canal de contacto de
                    www.midatacredito.com, enviando comunicación a la Carrera 7 # 76 – 35 o en el Centro de Experiencia ubicado
                    en la Avenida Américas # 62-84 Piso 2 Local 71-72, así como en el correo electrónico
                    servicioalciudadano@experian.com, y que podré obtener mayor información sobre el tratamiento de mi
                    información personal accediendo a la Política de Tratamiento de Datos Personales de EXPERIAN COLOMBIA en el
                    siguiente 
                    <a href="{{url_politica_privacidad}}" target="_blank">link</a>
                </p>
            </div>
        </div>
        <button class="botonEsc" [disabled]="!(emailAnonimo.valid && termsDark)" webActivity data-idtag="ESCANEO1" data-nametag="escanear-verificarEnLaRed" id="botonEscaneo"
            type="submit">
            Escanea gratis tu email
        </button>
        <app-micro-loader [error]="true" *ngIf="error"></app-micro-loader>
    </form>
</div>
<div class="boxLoading" *ngIf="loading">
    <img src="assets/img/animations/Loader_MiData.gif" alt="loading">
    <p>Estamos <b>escaneando</b> la web en búsqueda de posibles fugas de información.</p>
</div>

<div class="escaneo" [ngClass]="{'reports': reports}" *ngIf="!loading && escaneo != null">
    <div class="boxHash left-hash">
        <div class="hash">
            <p>#MIDATAProtege</p>
            <i class="fa fa-sort-down" aria-hidden="true"></i>
        </div>
    </div>
    <div *ngIf="!reports">
        <h5>¡PUEDES ESTAR TRANQUILO!</h5>
        <p class="txtResult">Escaneamos el correo: <b>{{email}}</b> y no genera resultados de información comprometida en la Web.</p>
    </div>
    <div *ngIf="reports">
        <h5>¡OH OH! ENCONTRAMOS ALGO</h5>
        <p class="txtResult">Escaneamos el correo: <b>{{email}}</b> en busca de información comprometida, a continuación el resultado:</p>
    </div>
    <div class="boxAlert">
        <div class="alertScan">
            <img src="assets/img/images/alerta_darkweb.svg" alt="alert">
            <p *ngIf="escaneo.stlTransactionData.responseMetadata.recordCount === 0">El fraude por suplantación ha aumentado después la pandemia.</p>
            <p *ngIf="escaneo.stlTransactionData.responseMetadata.recordCount === 1">Email y contraseña expuestas en: <span>{{escaneo.stlTransactionData.responseMetadata.recordCount}} Sitios Web</span></p>
            <p *ngIf="escaneo.stlTransactionData.responseMetadata.recordCount > 1">Contraseña expuesta en: <span>{{escaneo.stlTransactionData.responseMetadata.recordCount}} Sitios Web</span></p>
        </div>
        <div class="contTxt">
            <h6>¿QUIERES PROTEGERTE?</h6>
            <p class="txtPlan">Protege y monitorea tu información personal con Midatacrédito adquiriendo nuestro <b>PLAN PROTEGE</b> desde <b>{{sesion.planes.planes?.ant?.mensual?.[1]}}</b></p>
            <div class="checks">
                <div class="itemCheck">
                    <img src="assets/img/images/check_darkweb.svg" alt="check">
                    <p>Monitorea la vulnerabilidad de tus contraseñas y cuentas en internet.</p>
                </div>
                <div class="itemCheck">
                    <img src="assets/img/images/check_darkweb.svg" alt="check">
                    <p>Revisa que tus documentos personales no estén expuestos en la red.</p>
                </div>
            </div>
        </div>
    </div>
    <button id="btnDarWeb"
    (click)="seleccionarPlan(sesion.planes.planes?.ant, '1', sesion.planes.planes?.ant?.mensual?.[1], sesion.planes.planes?.ant?.mensual?.[0])">
    Protegerme del fraude
    </button>
</div>