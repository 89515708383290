import { Component, HostListener, Inject, Injectable, InjectionToken, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Plan } from 'src/app/core/models/plan.model';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $: any;
@Component({
  selector: 'app-comparacion-planes',
  templateUrl: './comparacion-planes.component.html',
  styleUrls: ['./comparacion-planes.component.scss']
})
export class ComparacionPlanesComponent implements OnInit {

  public planes = {} as any;
  public planesFil = [];
  public freePremium = {};
  public planPer = {} as Plan;
  public planAnt = {} as Plan;
  public planPre = {} as Plan;
  public scroll = false;
  public caracteristicasTotal = [
    {
      plan: "Historia de crédito:",
      free: true,
      per: true,
      ant: true,
      pre: true,
      caracteristicasPlan: [
         "Historia de crédito detallada",
         "Reclamar a las entidades si tienes algún problema con tu información financiera.",
         "Revisar tus huellas de consulta.",
         "Colocar Alertas al sistema financiero en caso de suplantación o fraude.",
         "Recibir comunicaciones sobre nuevas aperturas en tu historia de crédito."
      ]
    },
    {
      plan: "Entrenador financiero:",
      free: false,
      per: true,
      ant: true,
      pre: true,
      caracteristicasPlan: [
         "Conocer tu puntaje de crédito.",
         "Recibir comunicaciones sobre cambios en tu historia de crédito.",
         "Conocer tu diagnóstico detallado sobre tu perfil crediticio.",
         "Simular tu puntaje de crédito viendo el impacto de los pagos en tus obligaciones.",
         "Calcular la probabilidad de éxito ante solicitud de un crédito.",
         "Conocer el consolidado de tus deudas.",
         "Descargar tu Historia de Crédito en formato PDF.²"
      ]
    },
    {
      plan: "Tu presencia en internet:",
      free: false,
      per: false,
      ant: true,
      pre: true,
      caracteristicasPlan: [
         "Monitorear tus emails, contraseñas y  documentos personales expuestos en internet.",
      ]
    },
    {
      plan: "Evita el fraude en tus compras",
      free: false,
      per: false,
      ant: false,
      pre: true,
      caracteristicasPlan: [
         "Recibir una poliza anti-fraude ante el robo de identidad y ante el robo de tarjetas de crédito.¹",
         "Recibir asesoría legal personalizada.¹",
      ]
    }
  ];

  constructor(
    public sesion: SesionService, 
    public constant : ConstantesService,
    private dataLayerService: DataLayersService,
    private metadatosService: MetadatosService,
    private titulo: Title,
    private meta: Meta
    ) {
      titulo.setTitle('Planes para cumplir tus objetivos financieros - Midatacrédito');
      meta.updateTag({name: 'description', 
        content:"Pensando en tu futuro y bienestar, en Midatacrédito te ofrecemos diferentes planes para que logres tus objetivos financieros. Conócelos aquí."});

    this.planPer = this.constant.planPer;
		this.planAnt = this.constant.planAnt;
		this.planPre = this.constant.planPre;
    this.freePremium = this.constant.freePremium;
    this.planesFil = this.constant.planes.mensual;
  }

  ngOnInit() {
    this.tracking_dataLayer();
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/comparacion-planes');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event:any) {
    if (window.pageYOffset !== 0) {
      this.scroll = true;
      $(".header").hide();
      //$(".mdc-sm-icon").addClass("filter");
    } else {
      this.scroll = false;
      $(".header").show();
      //$(".mdc-sm-icon").removeClass("filter");      
    }
  }

  changePago(pago:any) {
    this.planesFil = this.constant.planes[pago];
    this.actualizar_dataLayer(pago);
  }

  /**
   * La función de este método es el envio de dos data layer. El primero distiguiendo el tipo de usuario que visita la página
   * para ese se utiliza el "userId". El segundo "view_item_list" para mostrar los diferentes productos.
   */
  tracking_dataLayer(){
    if (this.sesion.user){
      this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dataLayerService.dl_userId( null, 'anonimo' );
    }
    this.dataLayerService.dl_view_item_list( this.planPer, this.planAnt, this.planPre, 'COMPARACIÓN PLANES', 'Mensual' );
  }

  actualizar_dataLayer( periodo: string ){
    periodo = periodo[0].toUpperCase() + periodo.substring(1);

    let plan_per = this.planPer;
    let plan_ant = this.planAnt;
    let plan_pre = this.planPre;

    plan_per.mensual[0] = this.planesFil[0]['valorMes'];
    plan_ant.mensual[0] = this.planesFil[1]['valorMes'];
    plan_pre.mensual[0] = this.planesFil[2]['valorMes'];

    this.dataLayerService.dl_view_item_list( plan_per, plan_ant, plan_pre, 'COMPARACIÓN PLANES', periodo );
  }

}
