export const environment = {
  env: 'demo',
  production: true,
  gtmPruebas: true,
  domain: 'midatacredito.com',
  oktaConfig: {
    clientId: 'oBgBpJ0vwHZekr1yDr85FhATrrBFKaoXVZ6g/CJyvJg=',
  },
  country: 'CO',
  buscacredito: false,
  enlaceBuscaCredito: 'https://demo.buscacredito.midatacredito.com/',
  enlaceSSO: 'https://demo-usuario.midatacredito.com/',
  enlaceLogin: 'https://demo-usuario.midatacredito.com/login?product=midc',
  enlaceRegistro: 'https://demo-usuario.midatacredito.com/registro?product=midc',
  enlaceRecovery: 'https://demo-usuario.midatacredito.com/recovery?product=midc',
  enlaceEvidente: 'https://demo-usuario.midatacredito.com/seguridad?product=midc',
  enlacePerfil: 'https://demo-usuario.midatacredito.com/private/perfil?product=midc',
  enlaceAutorizacion: 'https://demo-usuario.midatacredito.com/private/autorizaciones?product=midc',
  webhooks: 'https://webhooks.remarkety.com/webhooks/store/',
  enlaceS3: 'https://d1gj9peb9ac569.cloudfront.net/',
  enlacePAD: 'https://demo.pontealdia.midatacredito.com/', 
  eva: "https://webchat.chatlayer.ai/embedded/m71yha6t:67aca6dc80c3347d4f333101", 
  reclamos: true,
  habeasData: false,
  APIEndpoint_bank: 'https://demo.buscacredito.midatacredito.com/bank-ws',
  APIEndPoints: {
    urlEndPoints: 'https://demo.midatacredito.com/',
    urlEndVeri: 'https://demo.midatacredito.com/verifica/',
    // SESION
    validationSession: 'authn-ws/authn/validateSession',
    signOff: 'authn-ws/authn/signOff',
    // HC
    getFullCreditHistory: 'crdthstry/MiScoreHome/getFullCreditHistory',
    // FREEPREMIUM
    freepremium: 'crdthstry/MiScoreHome/getCreditHistoryFreemium',
    // UPSELLING
    upsellingInfo: 'pymnts/upsell/info',
    upsellingPlan: 'pymnts/upsell/change/plan',
    // ESCANEO
    generic: 'drkweb/darkweb/identity/generic',
    identity: 'drkweb/darkweb/identity',
    info: 'drkweb/darkweb/identity/info',
    countdw: 'drkweb/darkweb/notification/count',
    listdw: 'drkweb/darkweb/notification',
    readdw: 'drkweb/darkweb/notification/read',
    // PROXIMO CREDITO
    probabilidad: 'simulator/probabilidadcredito',
    simular: 'simulator/solicitudcredito',
    //SIMULATOR
    scenarios: 'simulator/simulator/scenarios',
    score: 'simulator/simulator/featureengine',
    //DIAGNOSTICO ROBUSTO 
    robustDiagnostic: 'simulator/robust-diagnostic',
    // NOTIFICACIONES Y ALERTAS
    notificaciones: 'ntfctions/consultas/notificacion/read',
    consultaNotificaciones: 'ntfctions/consultas/notificacion/read',
    bell: 'ntfctions/consultas/notificacion/bell',
    // HUELLA DE CONSULTA
    huellas: 'crdthstry/huellas/read',
    // CONTACTO
    contact: 'admantfraud-ws/admin/contact',
    // ACTIVAR DESACTIVAR CELULAR
    codigo: 'ntfctions/antifraude/codigo',
    validar: 'ntfctions/antifraude/validar',
    active: 'ntfctions/antifraude/active',
    updateFree: 'ntfctions/antifraude/updateEmailUserFree',
    // CONSULTAR Y GUARDAR TIPOS DE ALERTAS
    getAlertsMongoWS: 'ntfctions/consultas/accountNotifications/readSession',
    saveAlertsMongoWS: 'ntfctions/consultas/accountNotifications/saveSession',
    // PAGOS
    pagos: 'pymnts/pagos',
    bancos: 'pymnts/listBanksPayU',
    check: 'pymnts/checkPendingPayments',
    changeMethod: 'pymnts/payments/changePaymentMethod',
    consultMethod: 'pymnts/payments/currentPaymentMethod',
    //GIFCARD - PROMO
    gifCard: 'pymnts/promocodes',
    // RECLAMACIONES
    create: 'rclmtion/reclamation/dispute',
    consultarTiposReclamos: 'rclmtion/reclamation/type',
    reclamo: 'crdthstry/MiScoreHome/reclamo',
    // ALERTAS AL SISTEMA FINANCIERO
    listar: 'alerts/alertas/listar',
    crear: 'alerts/alertas/crear',
    consultar: 'alerts/alertas/consultar',
    // FACTURACION
    document: 'billing/billing/document',
    download: 'billing/billing/download',
    // INFO ADICIONAL
    sessioninfo: 'ldnfosession/sessioninfo/ecs',
    promocode: 'ldnfosession/sessioninfo/promocode',
    loadParameters: 'ldnfosession/load-parameter/byKeyApp',
    // VERIFICAR EVIDENTE
    validateEvidente: 'validation/api/v1/evidente/validateCustomer',
    // FLUJO V2
    registerUser: 'nwuser-ws/new-user-ws/api/v1/user/CO/registerUser',
    updatePSession: 'authn-ws/authn/updatePasswordSession',
    firstAuthn: 'authn-ws/authn/firstAuthn',
    saveSession: 'authn-ws/authn/saveSession',
    // PAYU
    irPayU: 'vrfycstmr/verify/oneshotRequest',
    // WebCheckout
    paymentWC : 'pymnts/paymentWC',
    confirmationWC : 'pymnts/confirmationWC',
    // REPORTAR MORA
    reportarMora: 'tempservcs/tempservice/covid19Info',
    consultarMora: 'tempservcs/tempservice/covid19InfoQuery',
    authorizations: 'tempservcs/termsconditions/authorizations',
    updateAuthorizations: 'tempservcs/termsconditions/authorizations/update',
    //PONTE AL DÍA
    consultaDeudas: 'debtsmanager-ws/pontealdia/v1/debt',
    registerApplyButtonPAD: 'debtsmanager-ws/pontealdia/v2/registerApplyButtonPAD',
    urlEscala: 'debtsmanager-ws/pontealdia/v1/process/preload/info',
    captarImpresionesPadYa : 'debtsmanager-ws/pontealdia/v2/registerPadYaImpressions',
    // Nuevos términos y condiciones
    addCustomerLog: 'nwuser-ws/termsConditions/addCustomerLog',
    saveAcceptanceAdvertising: 'nwuser-ws/termsConditions/saveAcceptanceAdvertising',
    // Descarga de PDF HC
    sendPdfToEmail: 'thrdparty-ws/api/v1/oneshot/getPDFCreditHistory',
    //Historico score
    historyScore : 'crdthstry/ScoreHistory/getUserHistory',
    //Semaforo
    trafficscore : 'crdthstry/MiScoreHome/trafficscore',
    //utms
    setUtmPago: 'mrketing-ws/save-tracking'
  },
  Bank: {
    campaignInfo:'bank-ws/ecs/datacash/bank/v1/campaigninfo',
    applyCampaign: 'bank-ws/ecs/datacash/bank/v1/campaign',
    sodimacRegister: 'bank-ws/ecs/datacash/bank/v1/campaign/sodimacRegister',
    campaignInfoReturn: 'bank-ws/ecs/datacash/bank/v1/campaign/refund',
    applyCampaignReturn: 'bank-ws/ecs/datacash/bank/v1/campaign/registerrefund',
    listBc: 'bank-ws/ecs/datacash/bank/v1/homeOffers/midc'
  },
  payULatam: {
    TestPayU: true,
    notifyURL: 'https://demo.midatacredito.com/pago/confirmacion'
  },
  /*Inicio rutas chat datina*/
  urlChatDatina: "assets/scripts/prod/script-datina-midc-v3.2.js",
  styleDatina: "assets/scripts/prod/style-datina-midc-v2.css",
  /*fin rutas chat datina*/
  s3:{
    /*Inicio rutas documentos legales*/
    terminosYCondiciones:'tyc/terminos_y_condiciones.pdf',
    politicaDePrivacidad:'tyc/politica_de_tratamiento_de_datos_personales.pdf',
    autorizacionDatos:'tyc/autorizacion_obligatoria.pdf',
    autorizacionDatosOpcional:'tyc/autorizacion_opcional.pdf',
    terminosCondicionesApp:'tyc/terminos-condiciones.html',
    /*Fin Rutas documentos legales*/
    parametrosSemaforo : 'semaforo/demo/parametros-riesgo.json',
    parametrosIntermitencia : 'midc/demo/intermitencia.json',
    parametrosMenu : 'midc/demo/menu.json',
    infoPlanes : 'midc/demo/planes.json',
    planesYPagos : 'midc/demo/planes-y-pagos.pdf',
    parametrosCategoryBc : 'Buscacredito/demo/categorias.json',
    parametrosScoreAdvance : 'midc/demo/parametrosModalAdvance.json',
    parametrosModalHome: 'midc/demo/parametrosModalHome.json',
    sponsorsFeria : 'midc/demo/sponsorsFeria.json',
    pntdPath: 'pntd/demo/aliados.json',
    /* Nueva ruta de todos lo objetos de los logos */
    allLogos:'assets/demo/allLogos.json'
  },
  slotsBanners : {
    home : '/22857899675/Ad_unit_Prueba_MIDC',
    home2: '/22857899675/Ad_Unit_MIDC_Home_V2',
    home2Mobile: '/22857899675/Ad_Unit_MIDC_Home_V2_Mobile',
    historicoFree : '/22857899675/Ad_unit_Prueba_MIDC2',
    historicoFree2 : '/22857899675/Test_Ad_Unit_MIDC_Freemium',
    historicoFreeCuentas : '/22857899675/Test_Ad_Unit_MIDC_CuentasAbiertas',
    historicoFreeCuentasC: '/22857899675/Test_Ad_Unit_MIDC_CuentasCerradas',
    historicoScore: '/22857899675/Test_Ad_Unit_MIDC_HC00',
    reclamacionesFree: '/22857899675/Test_Ad_Unit_MIDC_Reclamaciones',
    huellasConsulta: '/22857899675/Test_Ad_Unit_MIDC_HuellasConsulta',
    feriamidc2024: '/22857899675/Ad_Unit_LandingFeria',
    servicioCliente: '/22857899675/Test_Ad_Unit_MIDC_Servicioalcliente',
    free_generarAlerta: '/22857899675/Test_Ad_Unit_MIDC_Alerta01',
    free_notificaciones: '/22857899675/Test_Ad_Unit_Notificaciones',
    pri_proximo_credito: '/22857899675/Ad_Unit_Mi_Proximo_Credito',
    faq: '/22857899675/Test_Ad_Unit_Faq',
    // Banners dashboard
    dash1: '/22857899675/Ad_Unit_MIDC_Dash01',
    dash2: '/22857899675/Ad_Unit_MIDC_Dash02',
    dash3: '/22857899675/Ad_Unit_MIDC_Dash03',
    dash4: '/22857899675/Ad_Unit_MIDC_Dash04',
    dash1Mobile: '/22857899675/Ad_Unit_MIDC_Dash05',
    dash2Mobile: '/22857899675/Ad_Unit_MIDC_Dash06',
    dash3Mobile: '/22857899675/Ad_Unit_MIDC_Dash07',
    //Banners landing PAD
    padYa1: '/22857899675/Ad_Unit_PADYA_01',
    padYa2: '/22857899675/Ad_Unit_PADYA_02',
    newHomeTop : '/22857899675/midc_web/home_top',
    newHomeMiddle : '/22857899675/midc_web/home_middle',
    newHomeBottom : '/22857899675/midc_web/home_bottom'
  }

};
