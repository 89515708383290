import { Injectable } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { Router } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public elementToScroll = '';

  constructor(public sesion: SesionService, public suscripcion: SuscripcionService, public router: Router) { }

  redirectPayAccess(id: any, url: string) {
    if (this.sesion.estadoUserF == 'ACTIVO' || this.sesion.estadoUserF == 'ACTIVE_PROCESS') {
      $(".btn-main-menu").removeClass("active");
      $(id).addClass("active");
      $(".btn-main-menu")[4].classList.add("active");
    }
    $("#new-header").hide('fast');
    if (this.sesion.viewFree) {
      this.seleccionarPlan(this.sesion.planes.planes.per, '1', this.sesion.planes.planes.per.mensual[1], this.sesion.planes.planes.per.mensual[0], 'M');
      // this.modal.modalPerfil('M');
      //this.router.navigate(['/comparacion-planes']);
    } else {
      this.router.navigate(['/private' + url ]);
    }
  }
  redirectAllAccess(id: any, url: string, target?: string) {
    $(".btn-main-menu").removeClass("active");
    $(id).addClass("active");
    $("#new-header").hide('fast');
    const utmParams = {
      utm_source: 'Midatacredito',
      utm_medium: 'Menu',
      utm_campaign: 'ModuloBC'
    }
    if (this.sesion.viewFree) {
      if (target !== undefined && target == 'modulo-bc'){
        this.router.navigate(['/freemium/home'], { queryParams: utmParams });
      } else {
        this.router.navigate(['/freemium' + url]);
      }
    } else {
      if (target !== undefined && target == 'modulo-bc'){
        this.router.navigate(['/private/home'], { queryParams: utmParams });
      } else {
        this.router.navigate(['/private' + url]);
      }
    }
    if (target != undefined) {
      this.elementToScroll = target;
    }
  }

  irProteccion(id: any) {
    if (this.sesion.poliza) {
      $(".btn-main-menu").removeClass("active");
      $(id).addClass("active");
    }
    $("#new-header").hide('fast');
    if (!this.sesion.poliza) {
      this.seleccionarPlan(this.sesion.planes.planes.pre, '1', this.sesion.planes.planes.pre.mensual[1], this.sesion.planes.planes.pre.mensual[0], 'M');
      // this.modal.modalPlans('M');
      //this.router.navigate(['/comparacion-planes']);
    } else {
      this.router.navigate(['/private/poliza-premium']);
    }
  }

  irPresencia(id: any) {
    if (this.sesion.proteccion) {
      $(".btn-main-menu").removeClass("active");
      $(id).addClass("active");
    }
    if (id != null) { $(id).addClass("active"); }
    $("#new-header").hide('fast');
    if (!this.sesion.proteccion) {
      this.seleccionarPlan(this.sesion.planes.planes.ant, '1', this.sesion.planes.planes.ant.mensual[1], this.sesion.planes.planes.ant.mensual[0], 'M');
      // this.modal.modalProtege('M');
      //this.router.navigate(['/comparacion-planes']);
    } else {
      this.router.navigate(['/private/mi-presencia']);
    }
  }
  irServicioCliente() {
		$("#new-header").hide('fast');
		if (this.sesion.viewFree && this.sesion.cookieValue) {
			this.router.navigate(['/freemium/servicio-cliente']);
		} else if(!this.sesion.viewFree && this.sesion.cookieValue) {
			this.router.navigate(['/private/servicio-cliente']);
		} else{
		this.router.navigate(['/servicio-cliente']);
		}
	}

  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any, funn: string) {
    // this.loading.open();

    //this.broker.promov = 3;
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;
    const plan_datalayer = plan.id.toLowerCase();
    this.sesion.setCookieServices('item_list_name', 'HISTORICO');
    this.sesion.setCookieServices('item_list_id', `historico-${plan_datalayer}`);

    if (this.sesion.user) {
      this.suscripcion.planSesion(true, funn);
    } else {
      this.suscripcion.planSesion(false, funn);
    }
    // this.loading.close();
  }

  scrollToElement() {
    setTimeout(() => {
      document.getElementById(this.elementToScroll)?.scrollIntoView({ block: 'start', behavior: 'smooth' })
      this.elementToScroll = '';
    }, 500);
  }

}
